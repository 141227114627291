
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

:root {
  --header-height: clamp(36px, 6.296vh, 68px);
  --sidebar-width: clamp(150px, 248px, 12.9vw);
  --sidebar-width-medium: clamp(180px, 297px, 15.48vw);
  --sidebar-pinned-width: clamp(38px, 58px, 3.02vw);
  --padding-30: clamp(20px, 30px, 1.562vw);
  --padding-16: clamp(10px, 16px, 0.83vw);
  --white: #F7F7F7;
  --dark: #212121;
  --dark-100: #9F9F9F;
  --gray: #53565A;
  --light-gray: #D9D9D9;
  --light: #FFFFFF;
  --light-100: #EAEAEA;
  --background: #f5f0f3;
  --primary: #C92026;
  --primary-light: #EE454B;
  --primary-200: #e49093;
  --primary-300: #eb9b9d49;
  --primary-dark: #981C20;
  --secondary: #99CA3C;
  --success: #66B918;
  --info: #26A9E6;
  --warning: #FFB30D;
  --danger: #B91818;
  --primary-900: #001539;
  --font-24: clamp(18px, 24px, 1.25vw);
  --font-20: clamp(14px, 20px, 1.04vw);
  --font-18: clamp(14px, 18px, 0.93vw);
  --font-16: clamp(12px, 16px, 0.83vw);
  --font-14: clamp(10px, 14px, 0.729vw);
  --font-12: clamp(8px, 12px, 0.625vw);
  --font-10: clamp(8px, 10px, 0.52vw);
  --font-8: clamp(6px, 8px, 0.417vw);
  --font-6: clamp(4px, 6px, 0.313vw);
  --font-4: clamp(2px, 4px, 0.208vw);
  --font-2: clamp(1px, 2px, 0.104vw);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  font-family: 'Roboto';
}


hr {
  background: var(--primary);
  height: 1px;
  border: none;
}

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();


$md-primary: (
    50 : #f9e4e5,
    100 : #efbcbe,
    200 : #e49093,
    300 : #d96367,
    400 : #d14147,
    500 : #c92026,
    600 : #c31c22,
    700 : #bc181c,
    800 : #b51317,
    900 : #a90b0d,
    A100 : #ffd6d6,
    A200 : #ffa3a4,
    A400 : #ff7071,
    A700 : #ff5758,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$qima-primary: mat.define-palette($md-primary);
$qima-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$qima-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$qima-theme: mat.define-light-theme((
  color: (
    primary: $qima-primary,
    accent: $qima-accent,
    warn: $qima-warn,
  ),
  typography: mat.define-typography-config(),
  density: 0
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($qima-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.button {
  cursor: pointer;
  border: 2px solid var(--primary);
  background: #888888;
  display: flex;
  align-items: center;
  color: var(--light);
  gap: var(--font-10);
  border-radius: clamp(6px, 10px, 0.52vw);
  padding: 4px var(--font-12);
  height: clamp(24px, 36px, 1.875vw);
  transition: all .5s ease;

  span {
    font-size: var(--font-14);
    font-weight: 500;
  }

  &.outline {
    color: var(--primary);
    background: var(--light);
    border: 2px solid var(--primary);

    svg {
      path {
        fill: var(--primary);
      }
    }

    &:hover {
      background: var(--primary-200);
      border-color: var(--primary-200);
      color: var(--primary);
      svg {
        path {
          fill: var(--primary);
        }
      }
    }

    &.green {
      background: var(--light);
      border: 2px solid var(--success);
      color: var(--success);

      svg {
        path {
          fill: var(--white);
        }
      }
    }

    &.red {
      background: var(--light);
      border: 2px solid var(--danger);
      color: var(--danger);
    }

    &.blue {
      background: var(--light);
      border: 2px solid var(--info);
      color: var(--info);
    }
  }

  &.green {
    border: none;
    background: var(--success);
    color: var(--light);

    &:hover {
      color: var(--light);
      background: #4D8C12;
    }
  }

  &.red {
    background: #DB272D;
    border: none;
    &:hover {
      color: var(--light);
      background: var(--danger);
      svg {
        path {
          fill: var(--light);
        }
      }
    }
  }

  &.blue {
    color: var(--light);
    border: none;
    svg {
      path {
        fill: var(--light);
      }
    }
    background: #1BB2E9;
    &:hover {
      color: var(--light);
      background: #0E6A8B;
    }

    &.outline {
      color: #0E6A8B;
      border: 1px solid #0E6A8B;
      background: var(--light);
      font-weight: 400;

      svg {
        path {
          fill: #0E6A8B;
        }
      }

      &:hover {
        background: #0E6A8B;
        color: var(--light);

        svg {
          path {
            fill: var(--light);
          }
        }
      }
    }
  }

  &:hover {
    color: #888888;
    background: var(--light);
  }
}

.form-group {
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: clamp(150px, 248px, 12.916vw);
  gap: 1px;
  flex-grow: 1;

  .form-label {
    color: #222222;
    font-size: var(--font-10);
    font-weight: 400;
    padding-left: 4px;
  }

  .form-input {
    height: clamp(24px, 36px, 1.875vw);
    border-radius: clamp(4px, 8px, 0.41vw);
    padding-left: var(--font-12);
    width: 100%;
    font-size: var(--font-14);
    line-height: var(--font-16);
    border: 1px solid #53565A;
  }

  .alert {
    font-size: var(--font-10);
    color: red;
    padding-left: 4px;
  }
}

.table-item {
  border: 1px solid var(--light-gray);
  border-radius: var(--font-10);
  thead {
    th {
      color: var(--primary);
      font-size: var(--font-14);
      font-weight: 500;
    }
  }

  tr {
    border: 1px solid var(--light-gray);
  }

  tbody {
    tr {

      td {
        font-size: var(--font-12);

        &:last-child {
          text-align: center;
        }
      }

      &:nth-child(odd) {
        background-color: var(--white);
      }
    }
  }
}

.flex-area {
  display: flex;
  gap: var(--font-12);
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;


  &.row {
    flex-direction: row;
  }

  &.column {
    flex-direction: column;
  }
}

strong {
  font-weight: 500;
  color: var(--gray);
}

p {
  font-size: var(--font-14);
  color: var(--gray);
  font-weight: 400;
}

.title {
  color: var(--gray);
}

input[type="radio"] {
  margin-left: 4px;
  height: 16px;
  width: 16px;
  border-radius: 20px;
}

input[type="radio"]:checked {
  accent-color: #DB272D;
  height: 16px;
  width: 16px;
  border-radius: 20px;
  border: 1px solid #DB272D;
}

.drag-item {
  padding: 10px;
  margin: 5px;
  background-color: #f1f1f1;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
              0 8px 10px 1px rgba(0, 0, 0, 0.14),
              0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
